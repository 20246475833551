<template>
  <div>
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(module || $route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @row-select="onRowSelect"
      @inline-update="onInlineUpdate"
    >
      <div slot="afterFilter">
        <b-row v-if="isLoaded && isInsertAllowed && showAddButton">
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                @click="addTask('form')"
                title="Create task in new window"
              >
                <i class="fa fa-toolbar fa-plus" /> Create
              </b-button>

              <b-button
                variant="outline-dark"
                @click="addTask('inline')"
                title="Create task inline"
                :disabled="dataTable.isInserting"
              >
                <i class="fa fa-toolbar fa-plus-square" /> Create inline
              </b-button>

              <b-button
                variant="outline-dark"
                title="Complete tasks"
                size="sm"
                :disabled="hasSelectedRows ? false : true"
                @click="completeTasks()"
              >
                <font-awesome-icon icon="check-circle" />
                Complete
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>

      <div slot="Status" slot-scope="props">
        <b-dropdown
          v-if="props.row && !props.row['Action']"
          :variant="getTaskStatusColor(props.row.Status)"
          size="sm"
          right
          :text="props.row.Status"
        >
          <b-dropdown-item
            v-for="(status, index) in getAvailableTaskStatuses(
              props.row.Status
            )"
            @click="changeTaskStatus(props.row.ID, status.name)"
            :key="`ddi-${index}`"
          >
            {{ status.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            v-if="profile.data.id === 165"
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
            title="Delete task"
          >
            <font-awesome-icon icon="ban" />
          </button>

          <button
            v-if="!props.row['Recurring'] && !props.row['Action']"
            class="btn btn-success btn-sm"
            title="Complete task"
            @click="completeTask(props.row.ID)"
          >
            <clip-loader
              class="mt-1"
              :size="14"
              color="#36D7B7"
              :loading="props.row.isCompleting === true"
            />
            <font-awesome-icon
              icon="check-circle"
              v-if="!(props.row.isCompleting === true)"
            />
          </button>

          <button
            v-if="!props.row['Recurring'] && props.row['Action']"
            class="btn btn-primary btn-sm"
            title="Submit activity"
            @click="submitActivity(props.row.ID)"
          >
            <clip-loader
              class="mt-1"
              :size="14"
              color="#36D7B7"
              :loading="props.row.isCompleting === true"
            />
            <font-awesome-icon
              class="ml-1"
              icon="bolt"
              v-if="!(props.row.isCompleting === true)"
            />
          </button>

          <button
            v-if="props.row['Recurring']"
            class="btn btn-secondary btn-sm"
            title="Postpone task"
            @click="postponeTask(props.row.ID)"
          >
            <clip-loader
              class="mt-1"
              :size="14"
              color="#36D7B7"
              :loading="props.row.isPostponing === true"
            />
            <font-awesome-icon
              icon="clock"
              v-if="!(props.row.isPostponing === true)"
            />
          </button>
          <button
            class="btn btn-info btn-sm"
            @click="editItem(props.row.ID)"
            title="Edit task"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>

          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
            title="View task"
          >
            <font-awesome-icon icon="eye" />
          </button>
          <button
            class="btn btn-info btn-sm"
            @click="cloneTask(props.row.ID)"
            title="Clone task"
          >
            <clip-loader
              class="mt-1"
              :size="14"
              color="#36D7B7"
              :loading="props.row.isCloning === true"
            />
            <font-awesome-icon
              icon="clone"
              v-if="!(props.row.isCloning === true)"
            />
          </button>

          <button
            class="btn btn-warning btn-sm"
            @click="emailTask(props.row.ID)"
            title="Email task"
          >
            <clip-loader
              class="mt-1"
              :size="14"
              color="#36D7B7"
              :loading="props.row.isEmailing === true"
            />
            <font-awesome-icon
              icon="envelope"
              v-if="!(props.row.isEmailing === true)"
            />
          </button>
          <button
            class="btn btn-dark btn-sm"
            @click="showContentModal(props.row.ID)"
            title="Preview task"
          >
            <font-awesome-icon icon="search" />
          </button>
          <button
            :class="[
              props.row['Description']
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleDescription(props.row['ID'])"
          >
            <font-awesome-icon icon="file" v-if="!props.row['Description']" />
            <font-awesome-icon
              icon="file-alt"
              v-if="props.row['Description']"
            />
          </button>
          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>

      <span slot-scope="props" slot="child_row">
        <ckeditor
          v-if="dataTable.childRow.showDescription"
          ref="ckeditor"
          @blur="onDescriptionBlur(props.row)"
          :editor="editor.mode"
          v-model="props.row['Description']"
          :config="editor.config"
        />
        <files-container
          ref="files"
          v-if="dataTable.childRow.showFiles"
          :module-id="$route.meta.module.id"
          :entity-id="props.row['ID']"
        ></files-container>
      </span>
    </table-custom>

    <b-modal
      ref="preview-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <b-row>
            <b-col lg="3">
              <strong>Task Name: </strong> {{ previewModal.taskName }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3">
              <strong>Date: </strong> {{ previewModal.startDate }}
            </b-col>
            <b-col lg="3">
              <strong>Date: </strong> {{ previewModal.dueDate }}
            </b-col>
            <b-col lg="3">
              <strong>Time start: </strong> {{ previewModal.timeStart }}
            </b-col>
            <b-col lg="3">
              <strong>Time end: </strong> {{ previewModal.timeEnd }}
            </b-col>
          </b-row>
          <b-row />
          <b-row>
            <b-col>
              <strong>Description: </strong>
              <b-card-body>
                <div class="preview-report" v-html="previewModal.description" />
              </b-card-body>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

import FilesContainer from '@/components/FilesContainer'

import CKEditor from '@ckeditor/ckeditor5-vue'
import InlineEditor from '@ckeditor/ckeditor5-build-inline'
import TableCustom from '@/components/TableCustom'

Vue.use(CKEditor)

import {
  completeTasks,
  submitActivity,
  completeTask,
  cloneTask,
  emailTask,
  postponeTask
} from './helpers.js'

import Vue from 'vue'

import { mapState } from 'vuex'

export default {
  name: 'ProjectTasks',
  props: {
    autoload: {
      type: Boolean,
      default: true
    },
    projectId: {
      type: [String, Number],
      default: ''
    },
    userId: {
      type: [String, Number],
      default: ''
    },
    filter: {
      type: String,
      default: ''
    },
    rowsPerPage: {
      type: Number,
      default: 5
    },
    filterByColumn: {
      type: Boolean,
      default: false
    },
    selectableRows: {
      type: Boolean,
      default: false
    },
    isNestedTable: {
      type: Boolean,
      default: false
    },
    isInsertAllowed: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    module: {
      type: Object,
      default: undefined
    }
  },
  components: {
    ckeditor: CKEditor.component,
    FilesContainer,
    TableCustom
  },
  data: function () {
    return {
      isLoading: false,
      isLoaded: false,
      editor: {
        mode: InlineEditor,
        data: '',
        config: {
          startupFocus: true,
          startupShowBorders: true
          // readOnly: true,
        },
        readonly: false
      },
      rawData: [],
      selectAll: false,
      selectedRows: [],

      dataTable: {
        view: 1,
        isLoading: false,
        isInserting: false,
        dataSet: [],
        options: {
          uniqueKey: 'ID',
          showChildRowToggler: false,
          filterByColumn: this.filterByColumn,
          /*filterable: this.filterByColumn
            ? [
                "ID",
                "Task Name",
                "Start Date",
                "Due Date",
                "Updated",
                "Status",
                "Priority",
                "Recurring",
                "Creator",
                "Private",
                "Assigned To",
                "Project Name"
              ]
            : [],*/
          columns: [
            'ID',
            'Task Name',
            'Start Date',
            'Due Date',
            'Updated',
            'Status',
            'Priority',
            'Recurring',
            'Creator',
            'Private',
            'Assigned To',
            'Project Name',
            'Actions'
          ],
          editableColumns: [
            'Task Name',
            'Start Date',
            'Due Date',
            'Status',
            'Priority',
            'Assigned To',
            'Recurring',
            'Private'
            //"Project Name"
          ],
          dropdownColumns: [
            { name: 'Status', options: [] },
            {
              name: 'Priority',
              options: []
            },
            {
              name: 'Recurring',
              options: []
            },
            {
              name: 'Private',
              options: this.$form.yesNo.options
            },
            { name: 'Assigned To', options: [] },
            { name: 'Project Name', options: [] }
          ],
          slots: ['Status'],

          perPage: 50,
          showCustomActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        childRow: {
          showDescription: false,
          showFiles: false
        }
      },

      previewModal: {
        ID: '',
        taskName: '',
        startDate: '',
        dueDate: '',
        timeStart: '',
        timeEnd: '',
        description: ''
      },
      assignedTo: {
        options: []
      },
      taskPriorities: {
        options: []
      },
      recurringTypes: {
        options: []
      },
      dblclick: undefined,
      serverFilter: undefined,
      html: {
        table: undefined,
        topScrollBar: undefined
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),

    hasSelectedRows () {
      return this.selectedRows.length > 0
    }
  },

  async mounted () {
    console.log('Tasks table mounted. userId:', this.userId)

    let self = this

    if (this.columns.length) this.dataTable.columns = this.columns

    self.$api.get('users').then(users => {
      self.$api.get('groups').then(groups => {
        const options = [
          {
            group_label: 'Groups',
            group_values: groups.map(u => ({
              id: u.groupid.toString(),
              label: u.groupname.trim()
            }))
          },
          {
            group_label: 'Users',
            group_values: users.map(u => ({
              id: u.id.toString(),
              label: u.full_name.trim()
            }))
          }
        ]

        self.assignedTo.options = options

        self.dataTable.options.dropdownColumns.find(
          c => c.name === 'Assigned To'
        ).options = users.map(u => ({
          id: u.id.toString(),
          label: u.full_name.trim()
        }))
      })
    })

    self.$api.get('tasks/priorities').then(response => {
      self.taskPriorities.options = response.map(u => u.name)

      self.dataTable.options.dropdownColumns.find(
        c => c.name === 'Priority'
      ).options = response.map(u => ({ id: u.name, label: u.name }))
    })

    self.$api.get('tasks/recurringtypes').then(response => {
      self.recurringTypes.options = response.map(u => u.name)

      self.dataTable.options.dropdownColumns.find(
        c => c.name === 'Recurring'
      ).options = response.map(u => ({
        id: u.name,
        label: u.name
      }))
    })

    self.dataTable.options.dropdownColumns.find(
      c => c.name === 'Status'
    ).options = self.$constants.TASK_STATUSES.map(u => ({
      id: u.name,
      label: u.name
    }))

    if (this.autoload) this.getData()
  },
  created () {},
  updated () {},
  methods: {
    onFilter () {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length
      this.highlightFilters()
    },

    onSelectAllChange (value) {
      for (let i = 0; i < this.dataTable.dataSet.length; i++) {
        let row = this.dataTable.dataSet[i]

        row['#'] = value

        Vue.set(this.dataTable.dataSet, i, row)

        if (value) this.selectedRows.push(row)
      }

      if (!value) this.selectedRows = []

      this.$emit('row-select', {})
    },

    onRowSelectorChange (e) {
      if (e.row['#'] === true) {
        this.selectedRows.push(e.row)
      }

      if (e.row['#'] === false) {
        this.selectedRows = this.selectedRows.filter(
          i =>
            i[this.dataTable.options.uniqueKey] !==
            e.row[this.dataTable.options.uniqueKey]
        )
      }

      this.$emit('row-select', e)
    },
    onRowSelect (e) {
      this.selectedRows = this.getSelectedRows()
      this.$emit('row-select', e)
    },
    getSelectedRows () {
      let selected = JSON.parse(
        JSON.stringify(this.$refs['dataTable'].selectedRows)
      )

      return selected
    },
    async getData (_payload) {
      console.log('TasksTable.getData._payload;', _payload)
      if (_payload) this.serverFilter = _payload

      await this.drawDataTable()
    },

    async drawDataTable () {
      this.dataTable.isLoading = true

      let url = ''
      let method = ''

      //all user tasks
      if (!this.projectId && !this.userId) {
        method = 'post'
        url = 'tasks'
      }

      //project tasks
      if (this.projectId) {
        method = 'get'
        url = `projects/${this.projectId}/tasks`
      }

      //user tasks
      if (this.userId) {
        method = 'get'
        url = `users/${this.userId}/tasks`
      }

      if (this.filter) {
        method = 'get'
        url = `projects/${this.projectId}/tasks/${this.filter}`
      }

      console.log('TasksTable.drawDataTable.url;', url)
      //20210130
      //let response = await this.$api.get(url);
      let response = await this.$api[method](url, this.serverFilter)

      this.rawData = response

      this.dataTable.isLoading = false
      this.isLoaded = true

      this.dataTable.dataSet = this.rawData

      //console.log("drawDataTable.rawData;", this.rawData);

      //create array of selected values for multiselect input
      this.dataTable.dataSet.forEach(row => {
        let values = []

        if (row['Assigned To'].length > 0) {
          let ids = row.assigned_to_ids.split(',')
          let names = row['Assigned To'].split(',')

          for (let i = 0; i < ids.length; i++) {
            values.push({
              id: ids[i].trim(),
              label: names[i].trim()
            })
          }
        }

        row.action = this.$helpers.getJSONObject(row['Action'])

        row.assignedTo = values
      })
      ///////////////////
      this.$emit('loaded', this.dataTable.dataSet.length)
    },

    addTask: function (mode) {
      if (mode === 'form') {
        this.$router.push({
          name: 'Task submission',
          params: {
            action: 'create',
            projectId: this.projectId
          }
        })

        return
      }

      //inline part
      let newTask = {
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        'Task Name': '',
        Description: '',
        Priority: 'Low',
        Status: 'Not Started',
        Recurring: '',
        Private: '',
        'Project Name': '',
        'Start Date': moment.utc().format('YYYY-MM-DD'),
        'Start Time': '',
        'Due Date': moment.utc().format('YYYY-MM-DD'),
        'End Time': '',
        'Account ID': '',
        'Account Name': '',
        Country: '',
        State: '',
        City: '',
        // assigned_to_ids: "",
        'Assigned To': '',
        // assignedTo: [],
        Creator: ''
      }

      /*
      let u = this.assignedTo.options.find(i => i.group_label === "Users");

      newTask.assignedTo.push(
        u.group_values.find(t => t.id === this.profile.data.id.toString())
      );
*/
      this.dataTable.isInserting = true
      this.$refs.dataTable.insertNewRow(newTask)
    },
    async saveNewRecordCallback (newtask) {
      console.log('saveNewRecordCallback.newtask', newtask)

      let self = this

      if (!newtask['Assigned To']) {
        self.$form.makeToastError('Please select assignee for new task')
        return
      }

      if (newtask['Task Name'].trim() === '') {
        self.$form.makeToastError('Please enter task name')
        return false
      }

      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      }

      data.task_name = newtask['Task Name']
      data.description = newtask['Description']
      data.date_start = newtask['Start Date']
      data.due_date = newtask['Due Date']

      data.assigned_to = [
        {
          id: newtask['Assigned To_ID'],
          label: newtask['Assigned To']
        }
      ]

      data.status = {
        id: newtask['Status'],
        label: newtask['Status']
      }

      data.priority = {
        id: newtask['Priority'],
        label: newtask['Priority']
      }

      data.recurringtype = {
        id: newtask['Recurring'],
        label: newtask['Recurring']
      }
      data.is_private = {
        id: newtask['Private'],
        label: newtask['Private']
      }

      data.project = {
        id: this.projectId
      }
      /*
      20211215
      data.project = {
        id: newtask["Project_ID"],
        label: newtask["Project"]
      };*/

      return self.$api
        .put('tasks', data)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          self.drawDataTable()

          self.dataTable.isInserting = false
        })
        .catch(function (error) {
          self.$form.makeToastError(error.message)

          self.drawDataTable()
        })
    },

    async revertNewRecordCallback () {
      this.dataTable.isInserting = false

      return true
    },
    onInlineUpdate (e) {
      const columnsMapping = [
        { alias: 'Task Name', dbName: 'task_name' },
        { alias: 'Start Date', dbName: 'date_start' },
        { alias: 'Due Date', dbName: 'due_date' },
        { alias: 'Status', dbName: 'status' },
        { alias: 'Priority', dbName: 'priority' },
        { alias: 'Recurring', dbName: 'recurring_type' },
        { alias: 'Description', dbName: 'description' },
        { alias: 'Assigned To', dbName: 'assigned_to' },
        { alias: 'Private', dbName: 'is_private' },
        { alias: 'Project', dbName: 'project' }
      ]

      let value = e.value

      if (e.column === 'Assigned To') value = [e.value]

      let dbName = columnsMapping.find(m => m.alias === e.column).dbName

      this.updateField(e.id, dbName, value)
    },
    updateField (id, field, value) {
      let self = this
      let params = {}
      params['id'] = id
      params[field] = value

      this.$api
        .put(`tasks/${this.id}`, params)
        .then(response => {
          self.$form.makeToastInfo(`${field}: ${response.message}`)
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(`${field}: ${response.message}`)
        })
    },
    postponeTask (id) {
      postponeTask(id, this)
    },
    completeTask (id) {
      completeTask(id, this)
    },
    submitActivity (id) {
      submitActivity(id, this)
    },
    cloneTask (id) {
      cloneTask(id, this, true)
    },

    emailTask (id) {
      emailTask(id, this, true)
    },

    viewItem: function (id) {
      this.$router.push({
        name: 'Task submission',
        params: {
          action: 'view',
          id: id
        }
      })
    },
    editItem: function (id) {
      this.$router.push({
        name: 'Task submission',
        params: {
          action: 'edit',
          id: id
        }
      })
    },
    deleteItem: async function (id) {
      let task = this.dataTable.dataSet.find(item => item.ID === id)

      let self = this

      self.$api
        .delete(`tasks/${task.ID}`)
        .then(() => {
          self.$form.makeToastInfo('Task deleted')
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(response.data.message)

          self.drawDataTable()
        })
    },
    showContentModal (id) {
      let task = this.dataTable.dataSet.find(item => item.ID === id)

      if (!task) return

      this.previewModal.ID = task.ID

      this.previewModal.title = `Task #${task.ID}`

      this.previewModal.assigned_to = task['Assigned To']
      this.previewModal.startDate = task['Start Date']
      this.previewModal.dueDate = task['Due Date']
      this.previewModal.timeStart = task['Start Time']
      this.previewModal.timeEnd = task['End Time']
      this.previewModal.taskName = task['Task Name']
      this.previewModal.description = task['Description']

      this.$refs['preview-modal'].show()
    },
    closeModal: function () {
      this.$refs['preview-modal'].hide()
    },
    toggleDescription (id) {
      this.dataTable.childRow.showDescription = !(this.dataTable.childRow.showFiles = false)

      this.$refs.dataTable.toggleChildRow(id)
    },
    toggleFiles (id) {
      this.dataTable.childRow.showDescription = !(this.dataTable.childRow.showFiles = true)
      this.$refs.dataTable.toggleChildRow(id)
    },
    onDescriptionBlur (row) {
      this.onInlineUpdate({
        id: row['ID'],
        column: 'Description',
        value: row['Description']
      })
    },
    loading (status) {
      this.isLoading = status
    },
    async completeTasks () {
      const selectedTasks = this.selectedRows.map(a => a['Task Name']).join()

      let confirm = await this.$form.showConfirmation(
        `Following tasks will be completed: <p>${selectedTasks}.</p>Do you want to proceed?`
      )

      if (!confirm) return

      let tasks_id = this.selectedRows.map(a => a['ID'])

      completeTasks(tasks_id, this)

      this.selectedRows = []
      this.$emit('bulk-complete')
    },
    getTaskStatusColor (status) {
      let color = 'danger'
      let item = this.$constants.TASK_STATUSES.find(t => t.name === status)

      if (item) color = item.color

      return color
    },
    getAvailableTaskStatuses (status) {
      return this.$constants.TASK_STATUSES.filter(t => t.name !== status)
    },
    changeTaskStatus (id, status) {
      let self = this
      let taskIdx = this.dataTable.dataSet.findIndex(item => item.ID === id)
      let task = this.dataTable.dataSet[taskIdx]

      task.Status = status

      Vue.set(this.dataTable.dataSet, taskIdx, task)

      //if task-action it can't be completed manually
      if (task.action) {
        self.$form.makeToastError(
          'Task can be completed only after activity submission'
        )
      }

      //if new task editing then return
      if (id === this.$constants.CUSTOM_TABLE.NEW_ROW_ID) return

      let data = {
        id: id,
        status: {
          id: status,
          label: status
        }
      }

      self.$api
        .put(`tasks/${id}`, data)
        .then(response => {
          self.$form.makeToastInfo(response.message)
        })
        .catch(function (error) {
          self.$form.makeToastError(error.response.statusText)
        })
    }
  },
  watch: {}
}
</script>

<style scoped>
/*
::v-deep table .multiselect {
  width: 140px;
}
  */
/*
::v-deep .table-responsive {
  overflow: visible;
}

::v-deep table {
  overflow: visible !important;
}
*/

.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 150px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}

.highlight {
  background-color: #ffffed;
}

/*
::v-deep .task-name-input {
  width: 200px;
}
  */

::v-deep .second-scrollbar-container {
  width: 82wv;
  border: none 0px;
  overflow-x: scroll;
  overflow-y: hidden;
}
::v-deep .second-scrollbar {
  width: 2000px;
  height: 20px;
}
</style>
